<template>
  <div>   
    <vs-prompt title="Exportar" class="export-options" @accept="exportToExcel" accept-text="Exportar" :active.sync="configExportExcel.activePrompt">
        <vs-input v-model="configExportExcel.fileName" class="w-full" />
        <v-select v-model="configExportExcel.selectedFormat" :options="configExportExcel.formats" class="my-4" />        
    </vs-prompt> 
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado && clienteSelecionado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">           
            <vs-alert class="mt-4 mr-4" color="danger" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>    
    <vs-row v-if="!exibirMensagemNenhumDadoEncontrado && listaVeiculosComBloqueio.length > 0 && clienteSelecionado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">             
            <vs-table :data="listaVeiculosComBloqueio" class="tabelaComScroll w-full mr-4" noDataText="..." search hoverFlat stripe id="tblVeiculosComBloqueio">
                <template slot="header">
                    <h6 class="mt-2 noprint" v-if="listaVeiculosComBloqueio.length > 0 "> {{listaVeiculosComBloqueio.length}} veículos com bloqueio</h6>    
                    <vs-button class="ml-2" icon="table_chart" color="success" @click="exibirPopUpExportarExcel" v-if="listaVeiculosComBloqueio && listaVeiculosComBloqueio.length > 0"></vs-button>                
                </template>
                <template slot="thead">
                    <vs-th class="p-0">Veículo</vs-th>                    
                    <vs-th class="p-0">Placa</vs-th>                    
                    <vs-th class="p-0">Condutor</vs-th>
                    <vs-th class="p-0">Responsável Bloqueio</vs-th>                    
                    <vs-th class="p-0">Senha</vs-th>                    
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                        <vs-td class="p-0"><small>{{tr.marca}} {{(tr.modelo === 'Outro' ? tr.modeloOutro : tr.modelo)}} </small>  </vs-td>
                        <vs-td class="p-0"><small class="font-bold"> {{tr.placa}} </small></vs-td>
                        <vs-td class="p-0"><small>{{tr.condutor}}</small></vs-td>
                        <vs-td class="p-0"><small>{{tr.nome}}</small></vs-td>                        
                        <vs-td class="p-0"><small>{{tr.senha}}</small></vs-td>                        
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
    <vs-row v-if="!exibirMensagemNenhumDadoEncontrado && listaVeiculosSemBloqueio.length > 0 && clienteSelecionado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">             
            <vs-table :data="listaVeiculosSemBloqueio" class="tabelaComScroll w-full mr-4" noDataText="..." search hoverFlat stripe id="tblVeiculosSemBloqueio">
                <template slot="header">
                    <h6 class="mt-2 noprint" v-if="listaVeiculosSemBloqueio.length > 0 "> {{listaVeiculosSemBloqueio.length}} veículos sem bloqueio</h6>                    
                </template>
                <template slot="thead">
                    <vs-th class="p-0">Veículo</vs-th>                    
                    <vs-th class="p-0">Placa</vs-th>                    
                    <vs-th class="p-0">Condutor</vs-th>                    
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                        <vs-td class="p-0"><small>{{tr.marca}} {{(tr.modelo === 'Outro' ? tr.modeloOutro : tr.modelo)}} </small>  </vs-td>
                        <vs-td class="p-0"><small class="font-bold"> {{tr.placa}} </small></vs-td>
                        <vs-td class="p-0"><small>{{tr.condutor}}</small></vs-td>                        
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "@/axios.js"
import generico from "@/generico.js"

export default {    
    created() {
        
    },
    data() {
        return {
            configExportExcel: { activePrompt: false, fileName: null, formats:["xlsx", "csv", "txt"] , 
                cellAutoWidth: true, selectedFormat: "xlsx", 
                headerTitle: ["Cliente", "Veículo", "Placa", "Condutor", "Responsável Bloqueio", "Senha"], 
                headerVal: ["nomeCliente", "modelo", "placa", "condutor", "nome", "senha"] 
            },
            atualizadoAs: null,
            exibirMensagemNenhumDadoEncontrado: false,
            listaClientes: [],
            listaVeiculosSemBloqueio: [],
            listaVeiculosComBloqueio: [],
            clienteSelecionado: null,            
            publicPath: process.env.BASE_URL,
            nomeCliente: null,
        }
    },
    methods: {        
        fetchListaVeiculos() {     
            if (!this.clienteSelecionado || !this.clienteSelecionado.id) {
                this.listaVeiculosSemBloqueio = null;
                this.listaVeiculosSemBloqueio = [];
                this.atualizadoAs = null;
                return;
            }
            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);
            
            axios.post("/RelatorioSenhaBloqueioVeiculos", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.atualizadoAs = generico.formatarTimestampParaDMYHMS(new Date().getTime());
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculosSemBloqueio = response.data.veiculosSemBloqueio;  
                    this.listaVeiculosComBloqueio = response.data.veiculosComBloqueio;  
                    this.nomeCliente = response.data.dadosCliente.nome;

                    if ((this.listaVeiculosSemBloqueio && this.listaVeiculosSemBloqueio.length > 0) || (this.listaVeiculosComBloqueio && this.listaVeiculosComBloqueio.length > 0)) {
                        this.exibirMensagemNenhumDadoEncontrado = false;
                    } else {
                        this.exibirMensagemNenhumDadoEncontrado = true;
                    }

                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'false');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        exibirPopUpExportarExcel(){
            this.configExportExcel.activePrompt = true            
            this.configExportExcel.fileName = "SenhasBloqueio_" + this.nomeCliente;
        },
        exportToExcel() {
            import('@/vendor/Export2Excel').then(excel => {
            const list = this.listaVeiculosComBloqueio;
            const data = this.formatJson(this.configExportExcel.headerVal, list)
            excel.export_json_to_excel({
                header: this.configExportExcel.headerTitle,
                data,
                filename: this.configExportExcel.fileName,
                autoWidth: this.configExportExcel.cellAutoWidth,
                bookType: this.configExportExcel.selectedFormat
            })        
            })
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {    
            return v[j]
            }))
        },
    },
    mounted() {               
        this.fetchListaClientes();        
    },
    beforeDestroy() {            
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
   } 
</style>

<style lang="scss">  
.con-vs-popup {    
    .vs-popup {
      width: 800px;      
    }
}
</style>